var $ = jQuery.noConflict();

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin'))
        method = 'wp-admin';

    var initializeBlock = function ($block) {

        // Counters
        // ------------------------------------------------------------------------------------------------

        function animateValue(obj, start, end, duration, separator, format) {
            let startTimestamp = null;
            let numberSpan = document.createElement('span');
            obj.appendChild(numberSpan);
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);

                let str = Number(Math.floor(progress * (end - start) + start));

                switch (format) {
                    case 'format1':
                        str = str.toLocaleString('pl-PL')
                        break;
                    case 'format2':
                        str = str.toLocaleString('de-DE')
                        break;
                    case 'format3':
                        str = str.toLocaleString('it-CH')
                        break;
                    case 'format4':
                        str = str.toLocaleString('ta-IN')
                        break;
                    case 'format5':
                        str = str.toLocaleString('zh-TW')
                        break;
                }

                str = str + separator;

                numberSpan.innerHTML = str
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

        var callback = function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!entry.target.classList.contains('init')) {
                        entry.target.classList.add("init");

                        let end = entry.target.getAttribute("data-end");
                        let format = entry.target.getAttribute("data-format");

                        if (!format)
                            format = 'standard';

                        let parts = end.split(',');
                        let duration = 2000;
                        entry.target.innerHTML = '';

                        let c = 0;
                        for (let part of parts) {
                            c++;
                            let separator = '';
                            if (c < parts.length)
                                separator = entry.target.getAttribute("data-separator");

                            duration = 2000;
                            if (part < 3) duration = 1;

                            animateValue(entry.target, 0, part, duration, separator, format);
                        }
                    }
                }
            });
        };
        const observer = new IntersectionObserver(callback, {});

        document.querySelectorAll('.counter').forEach(function (node) {
            observer.observe(node);
        });

    };

    window.onload = function () {
        initializeBlock($(this));
    }();

    if (window.acf) {
        window.acf.addAction('render_block_preview/type=hero-inner', initializeBlock);
    }
});
